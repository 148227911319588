import './bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.min.css';


import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
